import Navigo from 'navigo';

import FaqToggle from './Components/FAQ/FaqToggle';
import ScrollingSteps from './Components/Animation/ScrollingSteps';
import QuestionPopup from './Components/Question/QuestionPopup';
import { popupApp } from './Components/Popup/Popup';
import { PersonPicker } from './Components/Pricing/PersonPicker';
import Pricing from './Components/Pricing/Pricing';
import AddonPopover from './Components/Pricing/AddonPopover';
import MobileTabsDropdown from './Components/Tabs/MobileTabsDropdown';
import SaveUserURL from './Components/SaveUserURL/SaveUserURL';
import ContactForm from './Components/ContactForm/ContactForm';
import Faq from './Components/FAQ/Faq';
import LoginButtons from './Components/Navigation/LoginButtons';
import DemoGate from './Components/Demo/DemoGate';

const router = new Navigo('/');

router.on(/courses\/(.*)/, () => {
  FaqToggle();
  ScrollingSteps();
  MobileTabsDropdown();
  AddonPopover();
});

router.on(/demos\/(.*)/, () => {
  DemoGate();
  FaqToggle();
});

router.on('/der-sorglos-plan', () => {
  FaqToggle();
  ScrollingSteps();
  MobileTabsDropdown();
});

router.on('/en/der-sorglos-plan', () => {
  FaqToggle();
  ScrollingSteps();
  MobileTabsDropdown();
});

router.on('/pricing', () => {
  PersonPicker();
  Pricing();
  AddonPopover();
});

router.on('/en/pricing', () => {
  PersonPicker();
  Pricing();
  AddonPopover();
});

router.on('/pricing-all', () => {
  PersonPicker();
  Pricing();
  AddonPopover();
});

router.on('/en/pricing-all', () => {
  PersonPicker();
  Pricing();
  AddonPopover();
});

router.on('/plans', () => {
  PersonPicker();
  Pricing();
  AddonPopover();
});

router.on('/en/plans', () => {
  PersonPicker();
  Pricing();
  AddonPopover();
});

router.on('/kontakt', () => {
  ContactForm();
});

router.on('/en/kontakt', () => {
  ContactForm();
});

router.on('/faq', () => {
  Faq();
});

router.on('/en/faq', () => {
  Faq();
});

router.on('/components/demo-gate', () => {
  DemoGate();
  FaqToggle();
});

popupApp();
QuestionPopup();
SaveUserURL();
LoginButtons();

router.notFound(() => true);

router.resolve();
